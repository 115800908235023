#LandingPage {
  overflow: hidden;
  div.topGradientContainer {
    position: relative;
    padding-top: 80px;
    padding-bottom: 289px;
    margin-bottom: 200px;
    div.introductionSection {
      margin-bottom: 260px;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: repeat(12, 104px);
      column-gap: 24px;
      width: 1510px;
      div.leftSide {
        grid-column-start: 1;
        grid-column-end: 6;
        img#SaathiAppIcon {
          width: 80px;
          height: 80px;
          display: block;
          margin-top: 25vh;
          margin-bottom: 24px;
        }
        h1 {
          margin-top: 0px;
          margin-bottom: 24px;
        }
        p {
          opacity: 0.72;
        }

        a#getTheAppButton {
          background-image: linear-gradient(270deg, #0075f3 0%, #7d20d2 100%);
          border-radius: 16px;
          padding: 7px 24px;
          box-sizing: border-box;
          display: inline-flex;
          align-items: center;
          gap: 24px;
          cursor: pointer;
          transition: transform 0.5s;
          margin-bottom: 80px;
          justify-content: center;
          span {
            font-family: 'Saathi-UltraBold';
            font-size: 24px;
            color: #ffffff;
            letter-spacing: 0;
            line-height: 34px;
          }
          img {
            width: 28px;
            height: 28px;
            vertical-align: top;
          }

          &:hover {
            transform: scale(1.05);
          }
        }

        #suryodayLabel {
          display: flex;
          position: relative;

          width: 100%;
          max-width: 352px;
          padding: 10px;
          gap: 16px;

          span {
            font-family: 'Saathi-UltraBold';
            font-size: 12px !important;
            color: #363793;
            text-align: left;

            a {
              color: #fd8000;
            }
          }

          &:before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            mix-blend-mode: overlay;
            border-radius: 32px;
          }
        }
      }
      div.rightSide {
        grid-column-start: 7;
        grid-column-end: 13;
        
        padding-top: 120px;
        
        @keyframes device {
          0% { 
            transform: rotate(180deg)
            translate(6000px, 300px);
            opacity: 0
          }
          50% { 
            opacity: 1
          }
          100% { 
            transform: rotate(10deg) 
            translate(0)
          }
        }
        
        .device {
          position: relative;
          animation: device 1.5s cubic-bezier(0.645, 0.045, 0.355, 1.000) forwards;
          
          svg {
            position: absolute;
            z-index: 1000;
            opacity: 0;
            
            &:first-child {
              top: 170px;
              right: -70px;
              animation: create 31s ease-in-out forwards;
              
              @keyframes create {
                0% { opacity: 0 }
                5% { opacity: 0 }
                6% { opacity: 1 }
                25% { opacity: 1 }
                26% { opacity: 0 }
              }
            }
            
            &:nth-child(2) {
              top: 400px;
              left: -70px;
              animation: amount 31s ease-in-out forwards;
              
              @keyframes amount {
                0% { opacity: 0 }
                35% { opacity: 0 }
                36% { opacity: 1 }
                53% { opacity: 1 }
                54% { opacity: 0 }
              }
            }
            
            &:nth-child(3) {
              top: 550px;
              right: -120px;
              animation: merchant 31s ease-in-out forwards;
              
              @keyframes merchant {
                0% { opacity: 0 }
                55% { opacity: 0 }
                56% { opacity: 1 }
                72% { opacity: 1 }
                73% { opacity: 0 }
              }
            }
            
            &:nth-child(4) {
              top: 400px;
              left: -100px;
              animation: spend 35s ease-in-out forwards;
              
              @keyframes spend {
                0% { opacity: 0 }
                89% { opacity: 0 }
                90% { opacity: 1 }
                99% { opacity: 1 }
                100% { opacity: 1 }
              }
            }
          }
          
          video {
            position: absolute;
            z-index: 100;
            
            right: 50%;
            transform: translateX(50%);
            top: 40px;
            
            width: 100%;
            max-width: 360px;
            height: 765px;
            object-fit: cover;
            border-radius: 32px;
            
            @media (max-width: 1149px) {
              width: 259px;
              height: 540px;
              top: 34px;
              border-radius: 24px;
            }
          }
        }
        
        @media (max-width: 1550px) and (min-width: 1150px) {
          transform: scale(0.8);
        }
        
        @media (max-width: 1149px) {
          padding-top: 0px;
          margin-bottom: 60px;
          
          svg {                
            filter: 
              drop-shadow(0px 0px 8px #ffffff)
              drop-shadow(0px 0px 8px #ffffff);
            
            &:first-child {
              top: unset !important;
              bottom: -50px !important;
              left: 10% !important;
              
              path {
                display: none;
              }
              
              g#Create-a-goal path {
                display: block;
              }
            }
            
            &:nth-child(2) {
              top: unset !important;
              bottom: -60px !important;
              left: 25% !important;
              
              g {
                path {
                  &:first-child {
                    display: none;
                  }
                }
              }
            }
            
            &:nth-child(3) {
              top: unset !important;
              bottom: -80px !important;
              right: -10% !important;
              
              g {
                path {
                  &:last-child {
                    display: none;
                  }
                }
              }
            }
            
            &:nth-child(4) {
              top: unset !important;
              bottom: -70px !important;
              left: 30% !important;
              
              g {
                path {
                  &:last-child {
                    display: none;
                  }
                }
              }
            }
          }
        }
        
        img {
          display: block;
          margin: 0 auto;
          max-height: 830px;
          filter: drop-shadow(16px 16px 32px rgba(13,1,25,0.4));
          transform: translateZ(0);
          
          @media (max-width: 1149px) {
            height: 600px;
          }
        }
      }
    }

    div#moneyIsntGoodSection {
      position: relative;
      width: 1510px;
      margin-left: auto;
      margin-right: auto;
      h5 {
        margin-top: 0px;
        margin-bottom: 16px;
        font-family: 'Saathi-UltraBold';
        font-size: 24px;
        font-weight: 800;
        font-style: normal;
        letter-spacing: 1px;
        text-align: left;
        color: rgb(200, 153, 216);
      }
      h1 {
        line-height: 72px;
        margin-top: 0px;
        margin-bottom: 0px;
        width: 998px;
        //color: transparent;
        //background-image: linear-gradient(180deg, #a839bf 0%, #051fce 100%);
        //background-clip: text;
        //-webkit-background-clip: text;
        //-moz-background-clip: text;
      }
      
      span {  
        &.one {
          color: #9b37c0;
          opacity: 1;
        }
        &.two {
          color: #8334c3;
          opacity: 0.3;
        }
        &.three {
          color: #6b30c5;
          opacity: 0.3;
        }
        &.four {
          color: #572dc7;
          opacity: 0.3;
        }
        &.five {
          color: #2625cb;
          opacity: 0.3;
        }
      }
      
      &.play {
        span {    
          &.one {
            color: #9b37c0;
            animation: one 12s ease-in-out forwards;
            
            @keyframes one {
              0% { opacity: 1 }
              16% { opacity: 1 }
              20% { opacity: 0.3 }
              100% { opacity: 0.3 }
            }
          }
          &.two {
            color: #8334c3;
            animation: two 12s ease-in-out forwards;
            
            @keyframes two {
              0% { opacity: 0.3 }
              20% { opacity: 0.3 }
              24% { opacity: 1 }
              36% { opacity: 1 }
              40% { opacity: 0.3 }
              100% { opacity: 0.3 }
            }
          }
          &.three {
            color: #6b30c5;
            animation: three 12s ease-in-out forwards;
            
            @keyframes three {
              0% { opacity: 0.3 }
              40% { opacity: 0.3 }
              44% { opacity: 1 }
              56% { opacity: 1 }
              60% { opacity: 0.3 }
              100% { opacity: 0.3 }
            }
          }
          &.four {
            color: #572dc7;
            animation: four 12s ease-in-out forwards;
            
            @keyframes four {
              0% { opacity: 0.3 }
              60% { opacity: 0.3 }
              64% { opacity: 1 }
              76% { opacity: 1 }
              80% { opacity: 0.3 }
              100% { opacity: 0.3 }
            }
          }
          &.five {
            color: #2625cb;
            animation: five 12s ease-in-out forwards;
            
            @keyframes five {
              0% { opacity: 0.3 }
              80% { opacity: 0.3 }
              84% { opacity: 1 }
              100% { opacity: 1 }
            }
          }
        }
      }
      
      
      img#CurlGraphic {
        width: 310px;
        height: 174px;
        position: absolute;
        top: 241px;
        left: 939px;
      }
      h5#readSaathiManifesto {
        letter-spacing: 0px;
        position: absolute;
        top: 377px;
        left: 1178px;
        display: inline-block;
      }
    }
  }

  div#saveBetterWithSaathiContainer {
    padding: 0px 0px 200px 0px;

    h1 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 16px;
    }
    p.headerCaption {
      width: 762px;
      margin: 0px auto 82px auto;
    }
    div#gridContainer {
      margin: 0px auto;
      display: grid;
      grid-template-columns: repeat(12, 104px);
      column-gap: 24px;
      width: 1510px;
      h1.tabHeader {
        font: normal 800 48px 'Saathi-UltraBold';
        letter-spacing: 0px;
        text-align: left;
        line-height: 46px;
      }
      p.tabDescription {
        font-family: 'Telegraf-Regular';
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 24px;
      }

      div#leftPortion {
        grid-column-start: 2;
        grid-column-end: 7;
        margin-bottom: 24px;
        box-sizing: border-box;
        border-radius: 24px;
        background-color: rgba(0, 117, 243, 0.16);
        position: relative;
        color: rgba(0, 117, 243, 1);

        img#EarnBigRewardsBackground {
          position: absolute;
          left: 0px;
          top: 50px;
          width: 100%;
        }

        div.textSection {
          position: absolute;
          bottom: 103px;
          left: 48px;
          right: 48px;
          h1.tabHeader {
            margin-top: 0px;
            margin-bottom: 8px;
          }
          p.tabDescription {
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }

        a#viewOurMerchantsButton {
          position: absolute;
          bottom: 47px;
          left: 48px;
          background: rgba(0, 117, 243, 0.12);
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          padding: 13px 24px;
          box-sizing: border-box;
          cursor: pointer;
          text-decoration: none;
          span {
            font-family: 'Telegraf-Regular';
            font-size: 16px;
            letter-spacing: 0;
            font-weight: 600;
            color: rgba(0, 117, 243, 1);
          }
        }
      }

      div#rightPortion {
        margin-bottom: 24px;
        grid-column-start: 7;
        grid-column-end: 12;
        div.topSquare {
          margin-bottom: 23px;
          border-radius: 24px;
          background-color: rgba(125, 32, 210, 0.16);
          position: relative;
          box-sizing: border-box;
          padding: 0.05px; //to prevent margin collapse
          div.textSection {
            margin: 52px 213px 48px 48px;
            h1#percentFigure {
              color: rgba(125, 32, 210, 1);
              font-size: 146px;
              line-height: 24px;
              text-align: left;
              margin-top: 80px;
              margin-bottom: 40px;
              vertical-align: top;
              span {
                color: rgba(125, 32, 210, 1);
                font-family: 'Saathi-UltraBold';
                font-size: 56px;
                font-weight: 800;
                font-style: normal;
                letter-spacing: 0px;
                text-align: left;
              }
            }
            p.tabDescription {
              color: rgba(125, 32, 210, 1);
              margin: 0px;
            }
          }
          img#purpleRupeeCoin {
            width: 235px;
            position: absolute;
            top: -13px;
            right: 16px;
          }
          img#rupeeShadow {
            width: 144px;
            opacity: 0.32;
            position: absolute;
            bottom: 40px;
            right: 62px;
          }
        }

        div.bottomSquare {
          border-radius: 24px;
          background-color: rgba(248, 28, 163, 0.16);
          position: relative;
          padding: 0.05px; //to prevent margin collapse
          div.textSection {
            margin: 72px 160px 48px 48px;
            h1.tabHeader {
              margin-top: 0px;
              margin-bottom: 8px;
              font-family: 'Saathi-UltraBold';
              font-size: 48px;
              color: #f81ca3;
              letter-spacing: 0;
              line-height: 56px;
            }
            p.tabDescription {
              color: rgba(248, 28, 163, 1);
              width: 298px;
              margin-top: 0px;
              margin-bottom: 0px;
            }
          }
          img {
            position: absolute;
            &#girlBlackBeanie {
              width: 99px;
              top: -5px;
              right: 202px;
            }
            &#boyGreyBeanie {
              width: 118px;
              top: -6px;
              right: 35px;
            }
            &#boyThumbsUp {
              width: 138px;
              right: 25px;
              bottom: 11px;
            }
          }
        }
      }

      div#fullWidth {
        grid-column-start: 2;
        grid-column-end: 12;
        border-radius: 24px;
        background-color: rgba(0, 0, 0, 0.06);
        padding: 40px 56px;
        box-sizing: border-box;
        display: flex;
        gap: 40px;
        justify-content: space-between;
        div.leftPortion {
          margin-top: 17px;
          width: 40%;
          
          img {
            width: 213px;
            height: 48px;
            margin-bottom: 24px;
            display: block;
          }
          p {
            margin-top: 0px;
            margin-bottom: 0px;
            font-family: 'Telegraf-Regular';
            font-size: 16px;
            color: #363793;
            letter-spacing: 0;
            line-height: 23px;
          }
        }
        div.rightPortion {
          display: flex;
          justify-content: space-between;
          gap: 24px;
          div {
          width: 33%;
            
            text-align: center;
            box-sizing: border-box;
            svg {
              margin-bottom: 0px;
              margin: 0 auto;
              vertical-align: top;
            }
            h4 {
              font-family: 'Saathi-UltraBold';
              font-size: 24px;
              font-weight: 800;
              font-style: normal;
              letter-spacing: 0px;
              margin-top: 0px;
              line-height: 23px;
              margin-bottom: 8px;
            }
            p {
              margin-top: 0px;
              margin-bottom: 0px;
              font-size: 16px;
            }
            &#trusted {
              color: rgba(0, 117, 243, 1);
              svg {
                margin-bottom: 16px;
              }
            }
            &#secure {
              color: rgba(125, 32, 210, 1);
              svg {
                margin-bottom: 18px;
              }
            }
            &#safe {
              color: rgba(248, 28, 163, 1);
              svg {
                margin-bottom: 16px;
              }
            }
          }
        }
        h1.tabHeader {
          width: 400px;
          margin-top: 0px;
          margin-bottom: 8px;
        }
        p.tabDescription {
          width: 449px;
          margin: 0px;
          opacity: 0.5;
        }
        img#progressCirclesGroupPNG {
          position: absolute;
          bottom: 48px;
          right: 80px;
        }
        img#progressCirclesGroupSquarePNG {
          display: none;
        }
      }
    }
  }

  div#rewardsGalleryContainer {
    position: relative;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    
    img#rewardsBackground {
      position: absolute;
      z-index: -1;
      width: 100%;
      max-width: 1800px;
    }
    
    div#mobileBrandPicturesTopContainer,
    div#mobileBrandPicturesBottomContainer {
      display: none;
    }
    
    div#centralTab {
      width: 100%;
      max-width: 640px;
      margin: 100px 0;
      padding: 80px;
      box-sizing: border-box;
     
      border-radius: 24px;
      box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.1);
      background-color: rgba(255, 255, 255, 1);
      text-align: center;
      
      img#merchantLogos {
        margin-bottom: 24px;
        max-width: 100%;
      }
      
      h3 {
        margin-top: 0px;
        margin-bottom: 24px;
        font-family: 'Saathi-UltraBold';
        font-size: 48px;
        font-weight: 800;
        font-style: normal;
        letter-spacing: 0px;
        line-height: 46px;
      }
      
      p {
        font-family: 'Telegraf-Regular';
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: center;
        line-height: 24px;
        margin: 0px;
        opacity: 0.72;
      }
      
      div#viewMerchantsButton {
        display: inline-block;
        border-radius: 24px;
        background-color: rgba(0, 117, 243, 1);
        padding: 13px 39px 12px 23px;
        span {
          color: rgba(255, 255, 255, 1);
          font-family: 'Telegraf-Regular';
          font-size: 16px;
          font-weight: 600;
          font-style: normal;
          letter-spacing: 0px;
        }
      }
    }
  }

  div#KindOfCredit {
    width: 100%;
    position: relative;
    text-align: center;
    overflow: hidden; //to prevent margin collapsing
  }

  div#ProductFeedbacks {
    margin-top: -84px;
  }
}

@import './LandingPageSmallerScreen.scss';
@import './LandingPageMobile.scss';
