$animationSpeed: 40s;

@keyframes scrollRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(50% * -1));
  }
}

div#topCategoryCardsSlider {
  .slideTrack {
    animation: scrollRightToLeft $animationSpeed linear infinite;
  }
}
