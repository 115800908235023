@media (max-width: 1149px) {
  h1 {
    font-size: 32px !important;
    line-height: 40px !important;
  }

  p {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}
