@media (max-width: 1149px) {
  #AboutUsPage {
    section.topGradientContainer div.titleArea,
    section#comeSayHiSection {
      width: 900px;
      max-width: 100%;
      box-sizing: border-box;
      padding: 0px 24px;
      box-sizing: border-box;
    }

    section.topGradientContainer {
      div.titleArea {
        margin: 150px auto 90px auto;
      }
    }

    section#comeSayHiSection {
      p {
        margin-top: 16px;
        margin-bottom: 45px;
      }
    }
  }
}
