.saathi-savings-club {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  padding: 40px;
  width: 100%;
  height: 100%;
  border: 2px solid white;
  border-radius: 15px;
  background-color: #ffffff;
  box-sizing: border-box;

  img {
    margin-bottom: 15px;
  }

  h2 {
    font-size: 24px;
    color: #7d20d2;
    margin: 16px 0 32px;
  }

  h1 {
    font-size: 48px;
    color: #7d20d2;
    margin: 16px 0 32px;
  }
}

.hero {
  padding: 240px 0;

  @media (max-width: 800px) {
    grid-column: span 12;
    padding: 120px 0;
  }

  .content {
    grid-column: 2 / 6;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 800px) {
      grid-column: span 12;
    }

    div:hover {
      background-color: #f81ca2;
      color: #ffffff;
    }

    .notice {
      display: inline-flex;

      width: auto;
      background: #f8e8fc;
      padding: 8px 16px 8px 10px;
      border-radius: 40px;
      
      max-width: 200px;
      
      img {
        max-height: 20px;
      }

      svg {
        margin-right: 20px;
      }

      span {
        margin-left: 12px;
        font-size: 12px;
        font-weight: 900;
        line-height: 1.6em;
        
        white-space: nowrap;
      }
    }

    h1 {
      font-size: 48px;
      line-height: 1.2em;
      background-image: linear-gradient(60deg, #f81ca3, #7d20d2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      margin: 16px 0 32px;
    }

    p {
      max-width: 416px;

      font-size: 20px;
      line-height: 1.4em;
      color: #000000;
    }
  }

  .form {
    grid-column-start: 6;
    grid-column-end: 12;

    @media (max-width: 800px) {
      grid-column: span 12;
    }
  }
  
  .graphic {
    grid-column: 7 / 13;
    overflow: hidden;
    position: relative;
    height: fit-content;
    
    background: #292a2e;
    box-shadow: 0 8px 16px rgba(0,0,0,0.16);
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    
    &:hover {
      box-shadow: 0 16px 32px rgba(0,0,0,0.32);
    }
    
    @media (max-width: 800px) {
      grid-column: span 12;
    }
    
    .join-whatsapp {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      
      display: flex;
      justify-content: space-around;
      background: rgba(0,0,0,0.4);
      
      text-decoration: none;
      transition: all 0.5s ease-in-out;
      
      &:hover {
        background: rgba(0,0,0,0);
      }
      
      &-button {
        display: flex;
        align-items: center;
        
        margin: auto;
        padding: 16px 40px;
        
        color: #fff;
        background: rgba(0,0,0,1);
        border-radius: 40px;
        
        img {
          height: 24px;
        }
        
        span {
          margin-left: 16px;
          font-weight: 600;
          font-size: 20px;
        }
        
        &:hover {
          background: #2b8222;
        }
      }
    }
    
    img {
      max-width: 100%;
      object-fit: cover;
    }
  }
}
