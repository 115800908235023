@media (max-width: 1550px) and (min-width: 1150px) {
  main#MerchantPage {
    div.grid-container {
      display: grid;
      margin: 0px auto;
      grid-template-columns: repeat(12, 1fr);
      column-gap: 18px;
      width: 100%;
      max-width: 1280px;
      padding: 0 40px;
    }

    section#hero-section {
      div.grid-container {
        div.leftHandSide {
          p {
          }
          img#imgPlatformList {
            max-width: 220px;
          }
        }
        
        #laptopDeal {
          margin-top: 0;
        }
      }
    }

    section#whySaathi {
      div.subSection {
        &#whySaathiHeaderSection {
          h2 {
            font-size: 36px !important;
            line-height: 46px !important;
          }
        }
        &#graphicTabsSection {
          div.graphicTab {
            padding: 30px;
            img {
              max-height: 170px;
              margin-bottom: 18px;
            }

            h4 {
              margin-bottom: 16px;
              font-family: 'Saathi-UltraBold';
              font-size: 28px;
              line-height: 36px;
              font-weight: 800;
            }
          }
        }

        &#installationSection {
          width: 1120px;
          div.grid-container {
            div.leftHandSide {
              h4 {
                font-size: 42px !important;
                line-height: 46px !important;
              }
            }
            img {
              grid-column-start: 7;
              grid-column-end: 12;
              width: 100%;
              margin: auto 0px;
            }
          }
        }

        &#brandsSection {
          width: 1120px;
          div.leftHandSide {
            gap: 100px;
            h4 {
              font-size: 34px !important;
              line-height: 42px !important;
            }
            p {
            }
          }
        }
      }
    }
  }
}
