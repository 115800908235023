@media (max-width: 1149px) {
  div#modalContainer {
    #RegisterModal {
      top: 0px;
      transform: translateX(-50%);
      padding: 24px;
      width: 393px;
      max-width: 100%;
      height: 100%;
      overflow: scroll;
      box-sizing: border-box;
      border-radius: 0px;
      img#handUpPNG {
        margin: 6px auto 16px auto;
      }
      h1 {
        font-family: 'Saathi-UltraBold';
        font-size: 32px !important;
        font-weight: 800 !important;
        font-style: normal;
        letter-spacing: 0px;
        line-height: 40px !important;
      }
      p {
        margin-bottom: 30px;
      }

      div#contactDetailsGrid {
        div {
          &.label {
            span {
            }
          }
          &.data {
            span,
            a {
            }
          }
        }
      }
    }
  }
}
