div#saathiLogoLeftHandContainer,
div#rightHandNavBar {
  position: fixed;
  top: 25px;
  // z-index: 999999;
  z-index: 99;
}

div#dev {
  position: fixed;
  top: 25px;
  left: 206px;
  padding: 8px;
  color: #cfcfcf;
  font-weight: 700;
}

img#SaathiLogo {
  position: absolute;
  top: 20px;
  top: 37px;
  left: 145px;
  width: 136px;
  height: 32px;
  vertical-align: top;
  cursor: pointer;
  z-index: 999999;
}

div#saathiLogoLeftHandContainer {
  left: 145px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.72);
  backdrop-filter: blur(16px);
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  cursor: pointer;
  img {
    width: 136px;
    height: 32px;
  }
  &.developersPage {
    background: rgba(0, 0, 0, 0.72);
  }
}

div#rightHandNavBar {
  right: 145px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  padding: 8px 8px 8px 32px;
  border: 1px solid transparent;

  transition: all 0.5s linear;

  // right: 145px !important;

  &.scrolled {
    background: rgba(255, 255, 255, 0.72);
    backdrop-filter: blur(16px);
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 24px;
  }

  div#saathiHomeButton {
    border-right: 1px solid black;
    overflow: visible;
    width: 55px;
    height: 16px;
    position: relative;
    opacity: 0.5;
    cursor: pointer;
    svg#SaathiLoopsSVG {
      width: 31px;
      height: 24px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      fill: black;
    }
    &:hover,
    &.active {
      opacity: 1;
    }
  }

  //MAIN LINK BUTTONS
  .navButton {
    display: flex;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    opacity: 0.5;
    text-decoration: none;
    svg.smallArrowDown {
      width: 9px;
      height: 6px;
      margin-left: 6px;
      margin-bottom: 2px;
    }
    //DROP DOWN OPTIONS SELECTOR:
    div.linkOptions {
      width: 192px;
      position: absolute;
      top: 23px;
      left: -16px;
      display: flex;
      flex-direction: column;
      // gap: 4px; ////
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      background-color: rgba(255, 255, 255, 1);
      padding: 8px;
      z-index: 10;
      span {
        text-align: left;
        border-radius: 8px;
        font-family: 'Telegraf-Regular';
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 32px;
        padding-left: 8px;
        &:hover,
        &.active {
          background-color: rgba(244, 244, 244, 1);
        }
      }
    }
    span {
      color: black;
      font-family: 'Saathi-UltraBold';
      font-size: 16px;
      font-weight: 800;
      font-style: normal;
      letter-spacing: 0px;
      text-align: right;
      line-height: 15px;
    }

    &#savingsClubLink {
      a {
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        img#profileIcon {
          margin-right: 6px;
          width: 12px;
          margin-bottom: 1px;
        }
      }
    }

    &#joinWaitlistButton {
      padding: 13px 24px;
      position: relative;
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 1);
      cursor: pointer;
      opacity: 1;
      box-sizing: border-box;
      height: 40px;
      span {
        color: white;
        font-family: 'Saathi-UltraBold';
        font-size: 16px;
        font-weight: 800;
        font-style: normal;
        letter-spacing: 0px;
        text-align: right;
      }
    }

    &.active {
      opacity: 1 !important;
      &.dropdownOpen {
        svg.smallArrowDown {
          transform: rotate(180deg);
        }
      }
    }
    &:hover {
      opacity: 1 !important;
    }
  }

  &.developersPage {
    background: rgba(0, 0, 0, 0.72);
    div#saathiHomeButton {
      border-right: 1px solid white;
      svg#SaathiLoopsSVG {
        fill: white !important;
      }
    }
    .navButton {
      span {
        color: white;
      }
      &.active {
        span {
          color: white !important;
        }
      }
      &#joinWaitlistButton {
        background-color: white;
        span {
          color: black;
        }
      }
      div.linkOptions {
        border: 1px solid rgba(0, 0, 0, 0.94);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.9);
        background-color: rgb(54, 31, 76);
        background: rgba(0, 0, 0, 0.72);
        span {
          &:hover,
          &.active {
            background-color: rgb(49, 49, 49);
            background-color: rgb(76, 51, 100);
            color: white;
          }
        }
      }
    }
  }
}

@import './TopNavBarSmallerScreen.scss';
