#MerchantPage {
  overflow: hidden;
  div.grid-container {
    display: grid;
    grid-template-columns: repeat(12, 104px);
    column-gap: 24px;
    width: 1510px;
    margin: 0px auto;
  }

  button#findOutMoreButton,
  button#discoverDocsButton {
    border-radius: 16px;
    background-color: black;
    color: white;
    border: none;
    padding: 15px 24px;
    font-family: 'Saathi-UltraBold';
    font-size: 18px;
    font-weight: 800;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
    cursor: pointer;
    text-align: center;
    min-width: 250px;
    &:hover {
      background-color: rgb(48, 48, 48);
    }
  }

  section#hero-section {
    position: relative;
    padding-top: 301px;
    padding-bottom: 160px;
    box-sizing: border-box;
    div.grid-container {
      div.leftHandSide {
        grid-column-start: 1;
        grid-column-end: 6;
        h5 {
          margin-top: 0px;
          margin-bottom: 8px;
          font-family: 'Saathi-UltraBold';
          font-size: 16px;
          line-height: 15px;
          font-weight: 800;
          font-style: normal;
          letter-spacing: 1px;
          text-align: left;
          color: rgb(200, 153, 216);
        }
        h1 {
          position: relative !important;
          margin-top: 0px;
          margin-bottom: 24px;
        }
        p {
          position: relative !important;
          margin-top: 0px;
          margin-bottom: 24px;
          opacity: 0.72;
        }
        img#imgPlatformList {
          max-width: 300px;
          margin-bottom: 30px;
        }

        button#findOutMoreButton {
          display: block;
          min-width: 250px;
        }
      }
      img#laptopDeal {
        width: 1045px;
        height: auto;
        grid-column-start: 7;
        grid-column: span 7;
        margin-top: -109px;
        margin-left: 38px;
      }
    }
  }

  section#whySaathi {
    position: relative;
    div.subSection {
      &#whySaathiHeaderSection {
        margin-top: 120px;
        margin-bottom: 80px;
        h2 {
          grid-column-start: 1;
          grid-column-end: 7;
          font-size: 48px !important;
          line-height: 56px !important;
          font-family: 'Saathi-UltraBold';
          margin: 0px;
        }
        p {
          grid-column-start: 7;
          grid-column-end: 13;
          opacity: 0.5;
          margin: 0px;
          line-height: 32px;
        }
      }
      &#graphicTabsSection {
        margin-bottom: 24px;
        div.graphicTab {
          grid-column-start: span 4;
          text-align: center;
          padding: 48px 40px;
          border-radius: 16px;
          img {
            margin-bottom: 24px;
          }
          h4 {
            margin-bottom: 16px;
            font-size: 32px;
            line-height: 40px;
            font-weight: 800;
          }
          p {
            margin-bottom: 0px;
            line-height: 30px;
            opacity: 0.72;
          }
          &:nth-of-type(1) {
            background-color: rgba(0, 117, 243, 0.12);
          }
          &:nth-of-type(2) {
            background-color: rgba(125, 32, 210, 0.12);
          }
          &:nth-of-type(3) {
            background-color: rgba(248, 28, 163, 0.12);
          }
        }
      }

      &#installationSection {
        box-sizing: border-box;
        width: 1510px;
        margin: 0px auto 204px auto;
        div.grid-container {
          background-color: #f0f0f0;
          padding: 40px;
          box-sizing: border-box;
          border-radius: 16px;
          div.leftHandSide {
            grid-column-start: span 6;
            h4 {
              font-size: 48px !important;
              line-height: 52px !important;
              margin-top: 0px;
              margin-bottom: 8px;
            }

            p {
              margin-bottom: 20px;
              opacity: 0.72;
              line-height: 32px !important;
            }
          }
          img {
            grid-column-start: 8;
            grid-column: span 6;
            height: 224px;
            width: auto;
          }
        }

        // div.leftHandSide {
        //   grid-column-start: span 6;
        //   h4 {
        //     font-size: 48px !important;
        //     line-height: 52px !important;
        //     margin-top: 0px;
        //     margin-bottom: 8px;
        //   }

        //   p {
        //     margin-bottom: 0px;
        //     opacity: 0.72;
        //     line-height: 32px !important;
        //   }
        //   button {
        //     border-radius: 16px;
        //     mix-blend-mode: overlay;
        //     background-color: #000000;
        //   }
        // }
        // img {
        //   grid-column-start: 8;
        //   height: 224px;
        // }
      }

      &#brandsSection {
        display: flex;
        width: 1510px;
        margin: 0px auto 204px auto;
        justify-content: space-between;
        gap: 134px;
        div.leftHandSide {
          // border: 2px solid red;
          text-align: center;

          h4 {
            font-size: 40px !important;
            line-height: 48px !important;
            margin-top: 0px;
            margin-bottom: 49px;
          }
          p {
            opacity: 0.5;
            line-height: 32px;
            // text-align: left;
          }
        }
      }
    }
  }
}

@import './ForBusinessesPageSmallerScreen.scss';
@import './ForBusinessesPageMobile.scss';
