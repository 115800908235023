#ManifestoAnimation {
  position: relative;
  height: 400vh;
  margin-bottom: 275px;

  div {
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  object {
    position: relative;
    height: 100%;
    min-height: 50vh;
    max-height: 80vh;
    transform: translateX(var(--l, 0));
  }
}


