.club {
	background-image: linear-gradient(180deg, #FFFFFF 7%, rgba(255,255,255,0.50) 37%, rgba(255,255,255,0.50) 66%, #FFFFFF 100%), linear-gradient(180deg, rgba(141,67,198,0.30) 19%, rgba(238,28,167,0.30) 63%);
}

.container {
	display: grid;
	grid-template-columns: repeat(12,1fr);
	grid-gap: 1.5rem;
}

.wrap {
	width: calc(100vw - 4rem);
	max-width: 1510px;
	margin: auto;
}