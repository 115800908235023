#FAQPage {
  section.topGradientContainer {
    div.radialPinkBlueGradient {
      background: radial-gradient(
        rgba(153, 199, 251, 1) 0%,
        rgba(251, 164, 218, 1) 100%
      ) !important;
    }

    div.titleArea {
      margin-bottom: 60px;
    }
  }

  section#questionsAnswersSection {
    display: flex;
    margin: 60px auto 160px auto;
    gap: 152px;
    justify-content: center;
    div#sectionTitlesContainer {
      width: 360px;
      h5 {
        // background-color: #d6e9fd;
        font-size: 20px;
        line-height: 19px;
        padding: 16px 24px 13px 24px;
        border-radius: 16px;
        margin-top: 0px;
        margin-bottom: 27px;
        cursor: pointer;
        &:hover {
          background-color: #eef5fc;
        }
      }
    }

    div#QAsContainer {
      width: 743px;
      h5 {
        font-size: 24px;
        line-height: 23px;
        margin-top: 15px;
        margin-bottom: 8px;
      }
      p {
        margin-top: 0px;
        margin-bottom: 40px;
        font-size: 20px !important;
        line-height: 28px !important;
      }

      ul {
        margin-bottom: 40px;
        li {
          font-family: 'Telegraf-Regular';
          font-size: 20px !important;
          line-height: 28px !important;
        }
      }
    }
  }
}

@import './FAQPageSmallerScreen.scss';
@import './FAQPageMobile.scss';
