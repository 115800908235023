@media (max-width: 1149px) {
  #FAQPage {
    section.topGradientContainer {
      div.titleArea {
        margin: 150px auto 90px auto;
        // width: 900px;
        // max-width: 100%;
        // border: 2px solid red;
        padding: 0px 24px;
      }
    }

    section#questionsAnswersSection {
      margin-top: 60px;
      margin-bottom: 60px;
      div#sectionTitlesContainer {
        // display: none;
        h5 {
        }
      }

      div#QAsContainer {
        width: 900px;
        max-width: 100%;
        display: block;
        padding: 0px 24px;
        box-sizing: border-box;
        h3,
        h5 {
          text-align: center;
        }
        h3 {
          font-size: 32px !important;
          line-height: 40px !important;
          text-decoration: underline;
          margin-top: 0px;
          margin-bottom: 20px;
        }
        h5 {
          margin-top: 0px;
          margin-bottom: 12px;
        }
        p {
          font-size: 16px !important;
          line-height: 24px !important;
          margin-bottom: 30px;
        }
        ul {
          margin-bottom: 30px;

          li {
            font-size: 16px !important;
            line-height: 24px !important;
          }
        }
      }
    }
  }
}
