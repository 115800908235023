@media (max-width: 1550px) {
  #ForDevelopersPage {
    section.topGradientContainer {
      div.titleArea {
        margin-bottom: 313px;
      }
    }

    section#mainSection {
      gap: 80px;
      div#documentContainer {
        section {
          p {
            font-size: 16px !important;
            line-height: 24px !important;
          }
        }
      }
    }
  }
}
