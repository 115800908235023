@media (max-width: 1149px) {
  div#profileIconsGrid {
    width: auto;
    margin-bottom: 60px;
    gap: 23px 27px;
    div.userSquare {
      div#profileImage {
        width: 150px;
        height: 150px;
      }
      span#name {
        font-size: 20px !important;
      }
      span#dept {
        font-size: 16px !important;
      }
    }
  }
}
