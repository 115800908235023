#AboutUsPage {
  section.topGradientContainer {
    div.radialPinkBlueGradient {
      background: linear-gradient(
        rgba(251, 164, 218, 1) 0%,
        rgba(153, 199, 251, 1) 100%
      ) !important;
    }
    div.whiteningLinearGradient {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.5) 33%,
        rgba(255, 255, 255, 0.5) 73%,
        rgba(255, 255, 255, 1) 100%
      ) !important;
    }

    div.titleArea {
      margin-bottom: 171px;
      p {
        margin-bottom: 0px;
      }
    }
  }

  section#comeSayHiSection {
    * {
      text-align: center !important;
    }

    h1 {
      margin-top: 0px;
      margin-bottom: 16px;
      font-size: 48px;
      line-height: 56px;
    }
    p {
      margin-top: 16px;
      margin-bottom: 72px;
      line-height: 32px;
      opacity: 0.5;
    }
  }
}

@import './AboutUsPageMobile.scss';
