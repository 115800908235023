@media (max-width: 1149px) {
  main#MerchantPage {
    div.grid-container {
      width: 900px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0px 24px 0px 24px;
      box-sizing: border-box;
    }

    section#hero-section {
      padding-top: 144px;
      padding-bottom: 105px;
      div.grid-container {
        div.leftHandSide {
          * {
            text-align: center;
          }
          h5 {
          }
          h1 {
            margin-bottom: 16px;
          }
          p {
            margin-bottom: 24px;
          }
          img#imgPlatformList {
            width: 180px;
            max-width: 100%;
            display: block;
            margin: 0px auto 40px auto;
          }

          button#findOutMoreButton {
            margin: 0px auto 40px;
            min-width: 300px;
            max-width: 500px;
            width: 100%;
          }
        }
        img#laptopDeal {
          width: 600px;
          max-width: 100%;
          height: auto;
          margin: 0px auto;
        }
      }
    }

    section#whySaathi {
      text-align: center;
      div.subSection {
        &#whySaathiHeaderSection {
          margin-top: 90px;
          margin-bottom: 50px;
          h2 {
            font-size: 32px !important;
            line-height: 40px !important;
            margin-bottom: 20px;
          }
        }

        &#graphicTabsSection {
          gap: 24px;
          margin-bottom: 24px;
          div.graphicTab {
            padding: 25px;
            img {
              max-height: 170px;
              max-width: 100%;
              margin-bottom: 12px;
            }

            h4 {
              margin-top: 0px;
              font-size: 28px;
              line-height: 36px;
            }
          }
        }

        &#installationSection {
          padding: 0px 24px;
          margin-bottom: 100px;
          width: 900px;
          max-width: 100%;
          div.grid-container {
            padding: 25px;
            align-items: flex-start;
            box-sizing: border-box;
            width: 100%;
            div.leftHandSide {
              h4 {
                font-size: 36px !important;
                line-height: 40px !important;
              }
              p {
              }
              button#discoverDocsButton {
                width: 100%;
                margin-bottom: 24px;
              }
            }
            img {
              display: block;
              max-width: 100%;
              height: auto;
            }
          }
        }

        &#brandsSection {
          width: 900px;
          max-width: 100%;
          flex-direction: column;
          gap: 20px;
          padding: 0px 24px;
          box-sizing: border-box;
          text-align: center !important;
          margin-bottom: 100px;
          div.leftHandSide {
            margin: 0px;
            h4 {
              font-size: 30px !important;
              line-height: 38px !important;
              margin-bottom: 12px;
            }
            p {
            }
          }
        }
      }
    }
  }
}
