#ForDevelopersPage {
  background-color: black;
  section.topGradientContainer {
    position: relative;
    div.radialPinkBlueGradient {
      z-index: 1 !important;
      background: radial-gradient(
        rgba(0, 117, 243, 1) 0%,
        rgba(248, 28, 163, 1) 100%
      );
    }
    // 0075F3
    //  F81CA3
    div.greyBlackLinearGradient {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2 !important;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 1) 100%
      );
    }
    div.titleArea {
      z-index: 3;
      position: relative;
      margin-bottom: 363px;
      h5 {
      }
      h1,
      p {
        color: white;
      }
    }
  }

  section#mainSection {
    display: flex;
    gap: 153px;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: -195px;
    z-index: 3;
    div#sectionTitlesContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-right: 1px solid #41494f;
      padding: 3px 0px;
      margin-top: 6px;
      position: sticky;
      top: 50px;
      a.scrollLink {
        text-decoration: none;
        color: white;
        opacity: 0.5;
        display: block;
        font-size: 18px;
        line-height: 18px;
        font-family: 'Telegraf-Regular';
        font-weight: 600;
        text-align: right;
        cursor: pointer;
        margin-right: 18px;
        white-space: nowrap;
        &:hover {
          color: #fecce9;
          opacity: 0.72;
        }
        &.active {
          color: #f81ca3;
          opacity: 1;
        }
      }
      div#sectionSelectedMarker {
        position: absolute;
        right: -1px;
        width: 2px;
        height: 24px;
        background-color: #f81ca3;
        z-index: 2;
        transition: top 0.3s;
        transition-timing-function: ease-out;
      }
    }

    div#documentContainer {
      max-width: 871px;
      * {
        color: white;
      }
      section {
        margin-bottom: 40px;
        h4 {
          font-size: 40px;
          line-height: 48px;
          margin-top: 0px;
          margin-bottom: 16px;
        }
        h5 {
          font-size: 24px;
          line-height: 32px;
          margin-top: 0px;
          margin-bottom: 8px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          margin: 0px 0px 40px 0px;
          color: #b3b3b3;
        }

        &#UserJourney {
          img {
            width: 100%;
          }
        }
        &#ImplementationPrerequisites {
          p {
            margin-bottom: 8px;
          }
          ul {
            margin-top: 0px;
            margin-bottom: 16px;
            li {
              color: #b3b3b3;
            }
          }
        }
        &#JavascriptImplementation {
          p {
            span {
              color: #f81ca3;
              opacity: 1 !important;
            }
          }
          img {
            width: 100%;
            // border: 2px solid white;
            // margin-top: -20px;
            z-index: -1;
          }
        }
      }
    }
  }
}

@import './ForDevelopersPageSmallerScreen.scss';
@import './ForDevelopersPageMobile.scss';
