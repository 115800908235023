#LoadingRing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: inline-block;
  width: 80px;
  height: 80px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid white;
    border-radius: 50%;
    animation: LoadingRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgba(212, 238, 249, 1) transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes LoadingRing {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

#FullScreenLoader {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: rgba(246, 246, 247, 0.5);
  z-index: 9999 !important;
  #LoadingRing {
    display: block;
  }
}
