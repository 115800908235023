.appStoreButton {
  display: block;
  background-color: black;
  border-radius: 16px;
  max-width: 500px;
  margin: 0px auto 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;

  img {
    vertical-align: top;
  }
}
