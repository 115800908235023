@media (max-width: 1149px) {
  #BrandLogosSection {
    // border: 2px solid red;

    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px 14px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    // grid-template-columns: 80px 80px 80px;
    // gap: 10px 14px;
    // margin: 0px auto;

    max-width: 500px;
  }
}
