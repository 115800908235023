@media (max-width: 1149px) {
  div#modalContainer {
    #RegisterModal {
      top: 0px;
      transform: translateX(-50%);
      padding: 24px;
      width: 393px;
      max-width: 100%;
      height: 100%;
      overflow: scroll;
      box-sizing: border-box;
      border-radius: 0px;
      img#closePNG {
      }
      img#handUpPNG {
        margin: 6px auto 16px auto;
      }
      h1 {
        font-family: 'Saathi-UltraBold';
        font-size: 32px !important;
        font-weight: 800 !important;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 40px !important;
      }
      p {
        margin-bottom: 20px;
      }
      div#inputFieldsContainer {
        gap: 10px;
      }

      .MuiCheckbox-root {
        margin: 0px;
        margin-top: 10px;
        margin-bottom: -20px;
      }
      span#agreementLabel {
        margin-right: 0px;
        margin-left: 50px;
        margin-top: -40px;
        margin-bottom: 30px;
      }

      &.registered {
        border-radius: 24px;
        width: auto;
        min-width: 300px;
        h1 {
        }
        p {
          margin-bottom: 32px;
        }
        div#closeButton {
          width: auto;
          span {
          }
        }
      }
    }
  }
}
