@media (max-width: 1550px) and (min-width: 1150px) {
  #LandingPage {
    div.topGradientContainer {
      padding-top: 0px;
      padding-bottom: 197px;
      margin-bottom: 120px;
      div.introductionSection {
        grid-template-columns: repeat(12, 77px);
        column-gap: 18px;
        width: 1120px;
        div.leftSide {
          img#SaathiAppIcon {
            margin-top: 25vh;
            margin-bottom: 35px;
          }
          h1 {
            margin-bottom: 19px;
          }
          p {
            line-height: 30px;
            margin-bottom: 37px;
          }
        }
        div.rightSide {
          img {
            left: 0px;
          }
        }
      }
      div#moneyIsntGoodSection {
        grid-template-columns: repeat(12, 77px);
        column-gap: 18px;
        width: 1120px;
        h5 {
          margin-bottom: 30px;
        }
        h1 {
          grid-column-start: 1;
          grid-column-end: 9;
          width: 742px;
          font-size: 48px !important;
          font-weight: 800;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 56px !important;
        }
      }
    }

    div#saveBetterWithSaathiContainer {
      padding-bottom: 120px;
      h1 {
      }
      p.headerCaption {
        width: 741px;
        margin-bottom: 48px;
      }

      div#gridContainer {
        width: 1120px;
        grid-template-columns: repeat(12, 77px);
        column-gap: 18px;

        h1.tabHeader {
          font-size: 40px !important;
          font-weight: 800;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 38px !important;
        }

        p.tabDescription {
          font-size: 16px !important;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 28px !important;
        }

        div#leftPortion {
          grid-column-start: 1;
          grid-column-end: 7;

          img#EarnBigRewardsBackground {
            top: 32px;
          }
          div.textSection {
            h1.tabHeader {
              margin-top: 307px;
              margin-bottom: 8px;
            }
            p.tabDescription {
              color: rgba(0, 117, 243, 1);
              margin-top: 0px;
              margin-bottom: 0px;
            }
          }
          div#viewOurMerchantsButton {
          }

          // div.bottomSquare {
          //   height: 187px;
          //   h4 {
          //     opacity: 1;
          //     font-size: 18px !important;
          //     line-height: 18px !important;
          //     font-weight: 800;
          //     font-style: normal;
          //     letter-spacing: 0px;
          //     text-align: center;
          //   }
          //   p {
          //     font-family: 'Telegraf-Regular';
          //     font-size: 12px !important;
          //     font-weight: 600;
          //     font-style: normal;
          //     letter-spacing: 0px;
          //     text-align: center;
          //     line-height: 15px !important;
          //   }

          //   div#trusted {
          //     color: rgba(0, 117, 243, 1);
          //     svg {
          //       width: 35px;
          //       height: 36px;
          //       margin-bottom: 16px;
          //     }
          //   }
          //   div#secure {
          //     color: rgba(125, 32, 210, 1);
          //     svg {
          //       width: 36px;
          //       height: 35px;
          //       margin-bottom: 18px;
          //     }
          //   }
          //   div#safe {
          //     color: rgba(248, 28, 163, 1);
          //     svg {
          //       width: 36px;
          //       height: 36px;
          //       margin-bottom: 16px;
          //     }
          //   }
          // }
        }

        div#rightPortion {
          grid-column-start: 7;
          grid-column-end: 13;
          div.topSquare {
            div.textSection {
              margin: 110px 32px 32px 32px;
              h1#percentFigure {
                margin-top: 0px;
                margin-bottom: 32px;
                font-size: 100px !important;
                line-height: 0.3em !important;
                max-height: 120px;
                span {
                }
              }
              p.tabDescription {
                line-height: 24px;
              }
            }
            img#purpleRupeeCoin {
              width: 229px;
              width: 180px;
              top: -13px;
              right: 50px;
            }
            img#rupeeShadow {
              width: 100px;
              bottom: 105px;
              right: 87px;
              z-index: 99999;
            }
          }

          div.bottomSquare {
            div.textSection {
              margin: 110px 145px 32px 32px;
              h1.tabHeader {
                line-height: 42px;
                margin-top: 0px;
              }
              p.tabDescription {
                width: auto;
              }
            }
            img {
              &#girlBlackBeanie {
                width: 104px;
                top: 5px;
                right: 169px;
              }
              &#boyGreyBeanie {
                width: 94;
                height: 115px;
                top: -2px;
                left: 417px;
              }
              &#boyThumbsUp {
                width: 125;
                height: 133px;
                top: 125px;
                right: 20px;
              }
            }
          }
        }

        div#fullWidth {
          grid-column-start: 1;
          grid-column-end: 13;
          padding: 40px 56px;
          gap: 40px;
          div.leftPortion {
            margin-top: 17px;
            img {
            }
            p {
              font-size: 16px !important;
              line-height: 23px;
            }
          }
          div.rightPortion {
            div {
              svg {
                margin-bottom: 0px;
                vertical-align: top;
              }
              h4 {
                font-family: 'Saathi-UltraBold';
                font-size: 24px;
                font-weight: 800;
                font-style: normal;
                letter-spacing: 0px;
                margin-top: 0px;
                line-height: 23px;
                margin-bottom: 8px;
              }
              p {
                margin-top: 0px;
                margin-bottom: 0px;
                font-size: 16px;
              }
              &#trusted {
                color: rgba(0, 117, 243, 1);
                svg {
                  margin-bottom: 16px;
                }
              }
              &#secure {
                color: rgba(125, 32, 210, 1);
                svg {
                  margin-bottom: 18px;
                }
              }
              &#safe {
                color: rgba(248, 28, 163, 1);
                svg {
                  margin-bottom: 16px;
                }
              }
            }
          }
          h1.tabHeader {
            width: 400px;
            margin-top: 0px;
            margin-bottom: 8px;
          }
          p.tabDescription {
            width: 449px;
            margin: 0px;
            opacity: 0.5;
          }
          img#progressCirclesGroupPNG {
            position: absolute;
            bottom: 48px;
            right: 80px;
          }
          img#progressCirclesGroupSquarePNG {
            display: none;
          }
        }

        // div#fullWidth {
        //   grid-column-start: 1;
        //   grid-column-end: 13;
        //   height: 204px;
        //   padding: 35px 32px;
        //   h1.tabHeader {
        //     margin-bottom: 5px;
        //     width: 310px;
        //     line-height: 42px;
        //     height: 84px;
        //   }
        //   p.tabDescription {
        //     margin: 0px;
        //     line-height: 24px;
        //   }
        //   img#progressCirclesGroupPNG {
        //     width: 450px;
        //     bottom: 36px;
        //   }
        // }
      }
    }

    div#rewardsGalleryContainer {
      div#centralTab {
        top: 80px;
      }
    }

    div#ProductFeedbacks {
      margin-top: 124px;
      h1 {
        margin-top: 149px;
        font-size: 48px !important;
        line-height: 64px !important;
        margin-bottom: 11px;
      }
      p.headerCaption {
        font-size: 20px !important;
        line-height: 32px !important;
        margin-bottom: 63px;
      }
    }
  }
}
