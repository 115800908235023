@media (max-width: 1149px) {
  div#fullWidthContainerDiv {
    display: block;

    // width: 393px;
    // max-width: 100%;
    width: 100%;
    max-width: 393px;

    padding: 24px 24px 24px 20px;
    box-sizing: border-box;
    // border: 1px solid red;

    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    div.radialPinkBlueGradient,
    div.whiteningLinearGradient {
      display: none;
    }

    div#NavBarMobile {
      display: flex;
      justify-content: space-between;

      img#saathiLogo {
        width: 136px;
        height: 32px;
        cursor: pointer;
      }
      div#topRightButtonWrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          transition: transform 0.3s;
        }
      }
    }

    &.open {
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: 100;
      div.radialPinkBlueGradient,
      div.whiteningLinearGradient {
        display: block;
      }
      div#NavBarMobile,
      section#linksSection {
        margin-left: auto;
        margin-right: auto;
        width: 345px; ////
        max-width: 100%;
      }
      div#NavBarMobile {
        div#topRightButtonWrapper {
          img {
            transform: rotate(180deg);
          }
        }
      }
      &.developersPage {
        div.radialPinkBlueGradient {
          background: radial-gradient(rgba(0, 117, 243, 1) 0%, rgba(248, 28, 163, 1) 100%);
        }
        div.whiteningLinearGradient {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 1) 100%);
        }
        section#linksSection {
          color: white;
          a {
            color: white;
          }
        }
      }
    }

    section#linksSection {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      gap: 20px;

      :nth-child(1) {
        opacity: 0;
        transform-origin: top center;
        animation: scaleZ 250ms 20ms ease-in-out forwards;
        animation: scaleZ 350ms 20ms ease-in-out forwards;
        animation: scaleZ 500ms 20ms ease-in-out forwards;
        animation: scaleZ 350ms 20ms ease-in-out forwards;
      }
      :nth-child(2) {
        opacity: 0;
        transform-origin: top center;
        animation: scaleZ 250ms 40ms ease-in-out forwards;
        animation: scaleZ 500ms 40ms ease-in-out forwards;
        animation: scaleZ 350ms 40ms ease-in-out forwards;
      }
      :nth-child(3) {
        opacity: 0;
        transform-origin: top center;
        animation: scaleZ 250ms 60ms ease-in-out forwards;
        animation: scaleZ 500ms 60ms ease-in-out forwards;
        animation: scaleZ 350ms 60ms ease-in-out forwards;
      }
      @keyframes scaleZ {
        0% {
          opacity: 0;
          transform: scale(0);
        }
        80% {
          transform: scale(1.07);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }

      a {
        text-decoration: none;
        color: black;
        h1 {
          margin: 0px;
        }
      }

      div.linksContainer {
        h1 {
          margin: 0px;
          font-size: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          img {
            // width: 12px;
          }
        }

        div.dropdown {
          flex-direction: column;
          gap: 5px;
          height: 0px;
          overflow: hidden;
          margin-top: 5px;
          h5.subLink {
            margin-top: 0px;
            margin-bottom: 0px;
            margin-left: 50px;
            font-family: 'Telegraf-Regular';
            font-size: 24px;
            font-size: 22px;
            line-height: 30px;
            font-weight: 600;
            cursor: pointer;
          }
        }

        &.open {
          h1 {
            img {
            }
          }
          div.dropdown {
            display: flex;
            height: auto;
            h5.subLink {
            }
          }
        }
      }
    }

    div.fixedFooterContainer {
      position: absolute;
      bottom: 80px;
      width: 100%;
      margin-left: -24px;
      box-sizing: border-box;
      div#getTheAppButton {
        border-radius: 16px;
        background-color: rgba(0, 0, 0, 1);
        margin: 0px 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 24px;
        cursor: pointer;
        padding: 16px 24px;
        justify-content: center;
        span {
          color: rgba(255, 255, 255, 1);
          font-family: 'Saathi-UltraBold';
          font-size: 20px;
          font-weight: 800;
          font-style: normal;
          letter-spacing: 0px;
          text-align: center;
          line-height: 19px;
        }
        img {
          width: 28px;
          height: 28px;
          vertical-align: top;
        }
        &:hover {
          background-color: rgb(48, 48, 48);
        }
      }
      .appStoreButton {
        margin: 0px 24px;
        width: auto;
      }
    }
  }
}
