h1.sectionHeader {
  margin-top: 488px;
  margin-top: 304px;
  margin-bottom: 16px;
  text-align: center;
}
p.headerCaption {
  width: 762px;
  margin: 0px auto 30px auto;
}
div.centeringDiv {
  display: flex;
  justify-content: center;
  // margin-bottom: 555px;
  margin-bottom: 200px;
  div#feedbackCardsContainer {
    display: inline-flex;
    column-gap: 24px;
    .feedbackCard {
      width: 359px;
      height: 400px;
      border-radius: 16px;
      position: relative;
      > * {
        position: absolute;
        left: 40px;
      }
      svg {
        top: 40px;
      }
      p {
        font-family: 'Telegraf-Regular';
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 30px;
        margin: 0px;
        position: absolute;
        bottom: 97px;
        width: 279px;
      }
      span.name {
        font-family: 'Saathi-UltraBold';
        font-size: 16px;
        font-weight: 800;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        bottom: 58px;
      }
      span.savingReason {
        font-family: 'Telegraf-Regular';
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        bottom: 40px;
      }
      div.gradientEffect {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background-color: black;
        mix-blend-mode: overlay;
      }
      &:nth-of-type(1) {
        margin-top: 65px;
      }
      &:nth-of-type(2) {
        margin-top: 20px;
      }
      &:nth-of-type(3) {
        margin-top: 89px;
      }
      &:nth-of-type(4) {
        margin-top: 58px;
      }
      &:nth-of-type(5) {
        margin-top: 107px;
      }
      &:nth-of-type(6) {
        margin-top: 0px;
      }
    }
  }
}

@import './ProductFeedbacksMobile.scss';
