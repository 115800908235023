#ManifestoScroll {
  height: 600px;
  margin-bottom: 120px;
  overflow-x: scroll;
  overflow-y: hidden;
  
  padding: 0 120px 80px 0;
  
  &::-webkit-scrollbar {
    height: 0.5em;
  }
   
  &::-webkit-scrollbar-track {
    background-color: #ebf1fd;
  }
   
  &::-webkit-scrollbar-thumb {
    background-color: #dadff8;
    border-radius: 0.5em;
  }
  
  svg {
    height: 100%;
    width: auto;
    text-align: left;
  }
}

@import './ManifestoAnimationMobile.scss';
