@media (max-width: 1149px) {
  h1.sectionHeader {
    width: 393px;
    max-width: 100%;
    margin: 79px auto 11px auto;
    box-sizing: border-box;
    padding: 0px 24px;
  }
  p.headerCaption {
    width: 393px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0px 24px;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 40px;
  }
  div.centeringDiv {
    margin-bottom: 108px;
    div#feedbackCardsContainer {
      position: relative;
      left: -155px;
      column-gap: 19px;
      .feedbackCard {
        width: 287px;
        height: 320px;
        border-radius: 13px;
        p {
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 24px;
          width: 232px;
        }
      }
    }
  }
}
