section#earlyaccess,
section#earlyaccess_fail {
  font-size: 16px;
  color: black;
  text-align: center;
  padding: 30px;
  background-color: white;
  position: relative;
  padding-top: 120px;
  padding-bottom: 289px;


  p.centerAlign {
    text-align: center;
  }

  // test123

  img {
    width: 200px;
    height: auto;
    margin-bottom: 40px;
  }

  div {
    button {
      border-radius: 8px;
      padding: 4px;
      color: white;
      background-color: black;
      width: 120px;
    }
  }

  p.smallText {
    font-size: 12px;
    color: #4a54f1;
    text-align: center;
  }

  // ON MOBILE:
  @media (max-width: 800px) {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;

    p.centerAlign {
      text-align: center;
    }

    img {
      width: 85%;
      margin-bottom: 25px;

      width: 261px;
    }

    div {
      button {
        // border-radius: 8px;
        width: 90%;
        // max-width: 280px;
        padding: 10px;
        font-size: 20px;

        // hello
      }
    }

    p.smallText {
      font-size: 12px;
      color: #4a54f1;
      text-align: center;
    }
  }
}

// FAILURE PAGE SPECIFIC:
section#earlyaccess_fail {
  img {
    margin-bottom: 10px;
  }

  // ON MOBILE:
  @media (max-width: 800px) {
    p.centerAlign {
      b#pleaseTryAgain {
        margin-top: 10px !important;
        display: block;
      }
    }


  }
}
