@media (max-width: 1550px) and (min-width: 1150px) {
  h1 {
    font-size: 48px !important;
    line-height: 54px !important;
  }

  // p {
  //   font-size: 20px !important;
  //   line-height: 28px !important;
  // }
}
