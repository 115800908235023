#SavingsClubBanner {
  width: 100%;
  max-width: 1120px;
  margin: 0px auto 205px auto;
  display: flex;
  padding: 30px 35px;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  box-sizing: border-box;

  div.leftSide {
    width: 248px !important;
    height: 264px !important;
    position: relative;
    flex-shrink: 0;
    img {
      position: absolute;
      &:nth-of-type(1) {
        width: 123px;
        height: 127px;
        top: 0px;
        left: 0px;
      }
      &:nth-of-type(2) {
        width: 92px;
        height: 117px;
        top: 5px;
        right: 5px;
      }
      &:nth-of-type(3) {
        width: 109px;
        height: 117px;
        bottom: 0px;
        left: 0px;
      }
      &:nth-of-type(4) {
        width: 98px;
        height: 134px;
        bottom: 0px;
        right: 0px;
      }
    }
  }

  div.middle {
    text-align: center;
    * {
      text-align: center;
    }
    h1 {
      font-size: 40px;
      line-height: 38px;
      margin-top: 0px;
      margin-bottom: 16px;

      font-size: 48px;
      line-height: 54px;
    }
    p {
      font-size: 20px;
      line-height: 32px;
      opacity: 0.5;
      margin-top: 0px;
      margin-bottom: 24px;
    }
    button {
      background-color: #7d20d2;
      border-radius: 20px;
      font-size: 16px;
      line-height: 15px;
      color: white;
      padding: 12px 24px;
      border: none;
      position: relative;
      cursor: pointer;
      img {
        width: 8px;
        margin-left: 16px;
        position: relative;
        top: 1px;
      }
    }
  }

  div#signUpButton {
    padding: 15px 24px;
    display: inline-block;
    border-radius: 16px;
    background-color: #7d20d2;
    cursor: pointer;
    span {
      color: rgba(255, 255, 255, 1);
      font-family: 'Saathi-UltraBold';
      font-size: 18px;
      font-weight: 800;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      line-height: 18px;
    }
  }

  div.rightSide {
    width: 248px;
    height: 264px;
    position: relative;
    flex-shrink: 0;
    img {
      position: absolute;
      &:nth-of-type(1) {
        width: 106px;
        height: 147px;
        top: 5px;
        left: 30px;
      }
      &:nth-of-type(2) {
        width: 98px;
        height: 119px;
        top: 0px;
        right: 0px;
      }
      &:nth-of-type(3) {
        width: 105px;
        height: 107px;
        bottom: 0px;
        left: 0px;
      }
      &:nth-of-type(4) {
        width: 104px;
        height: 133px;
        bottom: 0px;
        right: 0px;
      }
    }
  }
}

@import url('./SavingsClubBannerMobile.scss');
