div#modalContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  div#opacifyingDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    animation-name: backgroundFadeIn;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
  }
  @keyframes backgroundFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  #RegisterModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: inline-block;
    background-color: white;
    border-radius: 24px;
    padding: 40px;
    z-index: 999;
    width: 487px;
    box-sizing: border-box;
    img#closePNG {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }
    img#handUpPNG {
      width: 64px;
      height: 64px;
      display: block;
      margin: 8px auto 24px auto;
    }
    h1 {
      font-family: 'Saathi-UltraBold';
      font-size: 40px !important;
      font-weight: 800;
      font-style: normal;
      letter-spacing: 0px;
      text-align: center;
      margin: 0px auto 14px auto;
    }

    p {
      font-family: 'Telegraf-Regular';
      font-size: 16px !important;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px !important;
      margin: 0px auto 30px auto;
      img#INPNG {
        width: 21px;
        height: 16px;
        vertical-align: middle;
      }
    }

    div#contactDetailsGrid {
      display: grid;
      grid-template-columns: 90px 1fr;
      gap: 24px 24px;
      margin-bottom: 24px;
      div {
        position: relative;
        &.label {
          span {
            font-family: 'Telegraf-Regular';
            font-size: 16px !important;
            font-weight: 600;
            text-align: center;
            line-height: 18px !important;
          }
        }
        &.data {
          span,
          a {
            display: block;
            font-family: 'Telegraf-Regular';
            font-size: 16px !important;
            font-weight: 400;
            line-height: 18px !important;
          }
        }
      }
    }
  }
}

@import './ContactUsModalMobile.scss';
