div#profileIconsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 45px 55px;
  margin: 0px auto 277px auto;
  justify-content: center;
  max-width: 1220px; // (5 * icon width (200px)) + (4 * gutter width (55px)) = 1220px
  div.userSquare {
    div#profileImage {
      width: 200px;
      height: 200px;
      border: 1px black;
      margin-bottom: 24px;
      background-size: contain;
      -webkit-mask-image: url('../../../assets/images/mask.svg');
      mask-image: url('../../../assets/images/mask.svg');
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      mask-size: contain;
      filter: saturate(0);
    }
    span {
      display: block;
      width: 100%;
      text-align: center;
      &#name {
        font-size: 24px;
        line-height: 32px;
        font-family: 'Saathi-UltraBold';
      }
      &#dept {
        font-size: 20px;
        line-height: 28px;
        font-family: 'Telegraf-Regular';
        opacity: 0.72;
      }
    }
  }
}

@import './TeamProfileIconsMobile.scss';
