footer {
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  padding: 80px 0;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  p {
    font-size: 14px;
    line-height: 1.4em;
  }

  .container {
    max-width: 1120px;
    padding: 0 40px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2rem;

    // Added by Dylan because major overflow on mobile:
    @media (max-width: 800px) {
      display: block;
    }

    .contact {
      grid-column: span 6;
      padding: 14px 0;

      @media (max-width: 800px) {
        grid-column-start: span 12;
        grid-column-end: auto;
      }

      img {
        max-height: 40px;

        margin-bottom: 40px;
      }

      &-details {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            display: inline-block;

            &:last-child {
              font-size: 14px;
            }
          }

          a {
            display: inline-block;
            color: #f81ca3;
            background: rgba(248, 28, 163, 0.16);
            padding: 8px 16px;
            border-radius: 40px;
            margin: 0 8px 8px 0;
            text-decoration: none;
            transition:
              background 0.3s ease-in-out,
              color 0.3s ease-in-out;

            &:hover {
              color: #000;
              background: #f81ca3;
            }
          }
        }
      }
    }

    .disclaimer {
      grid-column: span 5 / span 5;
      grid-column-start: 8;

      @media (max-width: 800px) {
        grid-column-start: span 12;
        grid-column-end: auto;
      }
    }

    .copyright {
      grid-column: span 6;

      @media (max-width: 800px) {
        grid-column-start: span 12;
        grid-column-end: auto;
      }
    }

    .links {
      grid-column: span 5 / span 5;
      grid-column-start: 8;

      @media (max-width: 800px) {
        grid-column-start: span 12;
        grid-column-end: auto;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        font-size: 14px;

        li {
          margin-right: 16px;

          a {
            padding: 14px 0;
            font-weight: bold;
            text-decoration: none;
            color: #fff;
          }
        }

        &:last-child {
          margin-top: 40px;
          font-size: 12px;
          opacity: 0.64;

          span {
            display: inline-block;
            position: relative;

            &:hover {
              cursor: help;
              opacity: 1;

              &::before {
                display: block;
              }
            }

            &::before {
              content: attr(data-text);
              position: absolute;
              bottom: 16px;
              transform: translateX(-50%);
              padding: 10px;
              border-radius: 10px;
              background: #000;
              color: #fff;
              text-align: center;
              display: none;
            }
          }
        }
      }
    }
  }
}
