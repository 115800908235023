@media (max-width: 1149px) {
  #LandingPage {
    div.topGradientContainer {
      padding-top: 94px;
      padding-bottom: 178px;
      margin-bottom: 40px;
      div.introductionSection {
        width: 900px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px 24px;
        box-sizing: border-box;
        margin-bottom: 80px;
        //overflow: hidden;

        text-align: center;
        div.leftSide {
          width: 100% !important;
          margin-bottom: 80px;
          img#SaathiAppIcon {
            margin: 0 auto;
            margin-bottom: 24px;
          }
          h1 {
            width: 100%;
            margin-top: 0px;
            margin-bottom: 16px;
            text-align: center;
          }
          p {
            margin-top: 0px;
            margin-bottom: 24px;
          }

          a#getTheAppButton {
            min-width: 300px;
            max-width: 500px;
            margin-bottom: 40px;
            width: 100%;
            span {
              font-size: 20px;
            }
            img {
              width: 24px;
              height: 24px;
            }
          }
          .appStoreButton {
            margin-bottom: 40px;
          }
          #suryodayLabel {
            margin: 0 auto;
            width: auto;
          }
        }
        // div.rightSide {
        //   //height: 475px;
        //   //overflow: visible;
        //   border-radius: 16px;
        //   div#phoneGradientBackground {
        //     border-radius: 16px;
        //   }
        //   img {
        //     height: 540px;
        //     top: auto;
        //     bottom: 88px;
        //     left: 50%;
        //     transform: translateX(-50%);
        //   }
        // }
      }

      div#moneyIsntGoodSection {
        padding: 0px 24px;
        box-sizing: border-box;
        width: 900px;
        max-width: 100%;
        h5 {
          font-size: 16px;
          font-weight: 800;
          font-style: normal;
          letter-spacing: 0.67px;
          text-align: left;
          color: rgb(200, 153, 216);
        }
        h1 {
          width: auto;
          height: auto;
          font-size: 32px;
          line-height: 40px;
        }
      }
    }

    div#saveBetterWithSaathiContainer {
      margin: 0px auto;
      padding: 40px 24px 0px 24px;
      width: 393px;
      width: 900px;
      max-width: 100%;
      box-sizing: border-box;
      h1 {
        margin-top: 0px;
        margin-bottom: 10px;
      }
      p.headerCaption {
        width: 100%;
        font-size: 20px !important;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: center;
        line-height: 28px !important;
        margin-bottom: 32px;
      }

      div#gridContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        h1.tabHeader {
          font-size: 32px;
          font-weight: 800;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
        }
        p.tabDescription {
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 24px;
        }

        div#leftPortion {
          margin-bottom: 0px;
          img#EarnBigRewardsBackground {
            width: 100%;
            max-width: 500px;
            position: relative;
            top: 0px;
            left: 0px;
            margin: 40px auto 42px auto;
          }

          div.textSection {
            position: relative;
            top: 0px;
            left: 0px;
            margin-bottom: 10px;
            padding: 0px 24px;
            box-sizing: border-box;
            // border: 1px solid red;
            h1.tabHeader {
              line-height: 30px;
              margin: 0px 0px 12px;
              width: auto;
            }
            p.tabDescription {
              color: rgba(0, 117, 243, 1);
              width: auto;
              margin: 0px;
            }
          }
          a#viewOurMerchantsButton {
            position: relative;
            top: 0px;
            left: 0px;
            margin: 0px 24px 24px;
            // border: 1px solid red;
          }

          // div.bottomSquare {
          //   width: auto;
          //   margin: 0px -24px 24px -24px;
          //   overflow: hidden;
          //   border-radius: 0px;
          //   height: auto;
          //   padding: 40px 0px;
          //   gap: 13px;
          //   h4 {
          //     font-size: 20px;
          //     line-height: 19px;
          //     margin-bottom: 9px;
          //   }
          //   p {
          //     font-size: 11px !important;
          //     line-height: 15px !important;
          //   }
          //   div#trusted {
          //     svg {
          //       width: 39px;
          //       height: 40px;
          //       margin-bottom: 17px;
          //     }
          //   }
          //   div#secure {
          //     svg {
          //       width: 40px;
          //       height: 38px;
          //       margin-bottom: 17px;
          //     }
          //   }
          //   div#safe {
          //     svg {
          //       width: 40px;
          //       height: 40px;
          //       margin-bottom: 16px;
          //     }
          //   }
          // }
        }

        div#rightPortion {
          display: block;
          margin: 0px;
          width: auto;
          div.topSquare {
            margin-bottom: 24px;
            height: auto;
            div.textSection {
              margin: 179px 24px 24px;
              h1#percentFigure {
                margin-top: 0px;
                margin-bottom: 24px;
                font-size: 100px !important;
                line-height: 0.4em !important;
              }
              p.tabDescription {
                margin-top: 0px;
              }
            }
            img#purpleRupeeCoin {
              width: 190px;
              top: -6px;
              right: -8px;
            }
            img#rupeeShadow {
              top: 200px;
              right: 14px;
            }
          }

          div.bottomSquare {
            height: auto;
            div.textSection {
              width: auto;
              margin: 224px 24px 24px;
              h1.tabHeader {
                line-height: 40px;
                margin-top: 0px;
                margin-bottom: 8px;
                width: 248px;
              }
              p.tabDescription {
                width: auto;
              }
            }

            img {
              width: 80px;
              &#girlBlackBeanie {
                width: 103px;
                height: 134px;
                top: 8px;
                right: 145px;
              }
              &#boyGreyBeanie {
                width: 94px;
                height: 115px;
                top: -7px;
                right: 15px;
              }
              &#boyThumbsUp {
                width: 125px;
                height: 133px;
                top: 123px;
                right: 0px;
              }
            }
          }
        }

        div#fullWidth {
          height: auto;
          flex-direction: column;
          // padding: 24px;
          padding: 40px 24px;
          gap: 48px;
          div.leftPortion {
            margin-top: 0px;
            width: 100%;
            img {
              margin: 0px auto 24px;
            }
            p {
              text-align: center;
            }
          }

          div.rightPortion {
            gap: 12px;
            div {
              svg {
                margin-bottom: 0px;
                vertical-align: top;
              }
              h4 {
                font-size: 20px;
                line-height: 19px;
                margin-bottom: 9px;
              }

              &#trusted {
                svg {
                  width: 39px;
                  height: 40px;
                  margin-bottom: 17px;
                }
              }
              &#secure {
                svg {
                  width: 40px;
                  height: 38px;
                  margin-bottom: 17px;
                }
              }
              &#safe {
                svg {
                  width: 40px;
                  height: 40px;
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }

    div#rewardsGalleryContainer {
      box-sizing: border-box;
      height: 813px;
      margin-top: 80px;
      display: block;
      overflow: hidden;
      img#rewardsBackground {
        display: none;
      }
      div#mobileBrandPicturesTopContainer {
        display: block;
        display: flex;
        justify-content: center;
        img {
          width: 457px;
          height: 176px;
        }
      }
      div#mobileBrandPicturesBottomContainer {
        display: block;
        display: flex;
        justify-content: center;
        
        img {
          width: 490px;
          height: 250px;
        }
      }
      div#centralTab {
        margin: 0 auto;
        padding: 40px;

        h3 {
          font-size: 32px;
          font-weight: 800;
          font-style: normal;
          letter-spacing: 0px;
          text-align: center;
          line-height: 30px;
        }
      }
    }

    #KindOfCredit {
      margin: 0px;
      padding: 0px 24px;
      box-sizing: border-box;
    }
  }
}
