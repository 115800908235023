#BrandLogosSection {
  display: grid;
  grid-template-columns: 160px 160px 160px;
  grid-template-rows: 80px 80px 80px;
  gap: 25px 25px;
  justify-items: center;
  align-items: center;

  div {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      //fade in over the course of 1 second, linger for 3 seconds and then fade out over course of 1 second:

      opacity: 0;
      transition: opacity 1s;
      //     animation: fadeInOut 5s infinite;

      @keyframes fadeInOut {
        0% {
          opacity: 0;
        }
        5.55555555555% {
          opacity: 1;
        }
        94.4444444444% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      //   @keyframes fadeInOut {
      //     0% {
      //       opacity: 0;
      //     }
      //     11.1111111111% {
      //       opacity: 1;
      //     }
      //     88.9999999999% {
      //       opacity: 1;
      //     }
      //     100% {
      //       opacity: 0;
      //     }
      //   }
    }
  }
}

@import './BrandLogosSectionMobile.scss';
