@media (max-width: 1149px) {
  #ForDevelopersPage {
    section.topGradientContainer div.titleArea,
    section#mainSection {
      width: 900px;
      max-width: 100%;
      display: block;
      padding: 0px 24px;
      box-sizing: border-box;
    }

    section.topGradientContainer {
      overflow: hidden;
      div.titleArea {
        margin: 150px auto 270px auto;
        br {
          display: none;
        }
      }
    }

    section#mainSection {
      margin: 0px auto;
      div#sectionTitlesContainer {
        display: none;
      }
      div#documentContainer {
        width: 100%;
        section {
          h4,
          h5 {
            text-align: center;
          }
          h4 {
            font-size: 32px !important;
            line-height: 40px !important;
          }
          p {
            // font-size: 16px !important;
            // line-height: 24px !important;
          }
        }
      }
    }
  }
}
