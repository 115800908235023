div#modalContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;

  div#opacifyingDiv {
   /* position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    animation-name: backgroundFadeIn;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
 }
  @keyframes backgroundFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    } */
  }

  div#DownloadAppModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: inline-block;
    background-color: white;
    border-radius: 24px;
    padding: 56px 72px;
    z-index: 999;
    box-sizing: border-box;
    img#closePNG {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }
    img#qrCode {
      //   border: 2px solid red;
      width: 232px;
      display: block;
      margin: 0px auto 12px;
    }

    div#storeLogos {
      display: block;
      width: 210px;
      margin: 0px auto 20px;
      position: relative;
      padding: 0.05px; //to stop margin collapsing
      div#imagesContainer {
        display: flex;
        margin-bottom: 8px;
        justify-content: space-between;
        img {
          height: 60px;
          display: block;
          cursor: pointer;
          // opacity: 0.3;
          // &:hover,
          // &.active {
          //   opacity: 1;
          // }
        }
      }
      div#activeUnderline {
        border-bottom: 1px solid #0075f3;
        width: 100px;
        position: absolute;
        bottom: 0px;
        transition: left 0.5s;
        &.left {
          left: 0px;
        }
        &.right {
          left: calc(100% - 100px + 12px);
        }
      }
    }

    h2 {
      text-align: center;
      font-size: 40px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      margin: 0px auto 8px;
    }
    p {
      font-family: 'Telegraf-Regular';
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      line-height: 24px;
      margin: 0px auto;
      max-width: 300px;
    }
  }
}
