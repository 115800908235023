div#modalContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  div#opacifyingDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    animation-name: backgroundFadeIn;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
  }
  @keyframes backgroundFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  #RegisterModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: inline-block;
    background-color: white;
    border-radius: 24px;
    padding: 40px;
    z-index: 999;
    width: 487px;
    box-sizing: border-box;
    img#closePNG {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }
    img#handUpPNG {
      width: 64px;
      height: 64px;
      display: block;
      margin: 8px auto 24px auto;
    }
    h1 {
      font-family: 'Saathi-UltraBold';
      font-size: 40px !important;
      font-weight: 800;
      font-style: normal;
      letter-spacing: 0px;
      text-align: center;
      margin: 0px auto 8px auto;
    }

    p {
      font-family: 'Telegraf-Regular';
      font-size: 16px !important;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0px;
      text-align: center;
      line-height: 24px !important;
      margin: 0px auto 40px auto;
      img#INPNG {
        width: 21px;
        height: 16px;
        vertical-align: middle;
      }
    }

    div#inputFieldsContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .MuiTextField-root {
        background-color: #f0f0f0;
        input {
          outline: none !important;
          border: none;
        }
      }

      // .MuiTextField-root.standard {
      //   padding: 0px;
      //   position: relative;
      //   .MuiInputLabel-root {
      //     margin: 0px;
      //     position: relative;
      //     top: 0px;
      //     left: 0px;
      //   }
      //   .MuiFormHelperText-root {
      //   }
      // }
    }

    .MuiCheckbox-root {
      margin: -13px;
      &.Mui-checked {
        color: rgb(200, 153, 216);
      }
    }
    span#agreementLabel {
      font-family: 'Telegraf-Regular';
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      margin-left: 16px;
      margin-bottom: 20px;
      margin-top: 21px;
      display: inline-block;
    }

    div#submitButton {
      background-color: black;
      padding-top: 17px;
      padding-bottom: 16px;
      text-align: center;
      border-radius: 16px;
      cursor: pointer;
      span {
        color: white;
        font-family: 'Saathi-UltraBold';
        font-size: 24px;
        font-weight: 800;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 23px;
      }
      &.inactive {
        opacity: 0.15;
      }
      &.active {
        opacity: 1;
      }
    }

    &.registered {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      height: auto;

      h1 {
        text-align: center;
      }

      p {
        text-align: center;
        margin-bottom: 32px;
      }

      div#closeButton {
        background-color: black;
        padding-top: 17px;
        padding-bottom: 16px;
        text-align: center;
        border-radius: 16px;
        cursor: pointer;
        border-radius: 16px;
        background-color: rgba(227, 227, 227, 1);
        width: 407px;
        span {
          color: black;
          font-family: 'Saathi-UltraBold';
          font-size: 24px;
          font-weight: 800;
          font-style: normal;
          letter-spacing: 0px;
          text-align: left;
          line-height: 23px;
        }
      }
    }
  }
}

@import './RegisterModalMobile.scss';
